import { ThemeProvider } from '@audi/audi-ui-react';
import { AsyncSsrManagerV1 } from '@feature-hub/async-ssr-manager';
import { FeatureAppDefinition, FeatureAppEnvironment, FeatureServices } from '@feature-hub/core';
import type { Logger } from '@feature-hub/logger';
import { ReactFeatureApp } from '@feature-hub/react';
import { SerializedStateManagerV1 } from '@feature-hub/serialized-state-manager';
import {
  ContentContextProvider,
  FootnoteContextProvider,
  InViewContextProvider,
} from '@oneaudi/feature-app-utils';
import { ContentServiceV1 } from '@oneaudi/content-service';
import { AudiFootnoteRefernceServiceScopeManagerInterfaceV3 } from '@oneaudi/footnote-reference-service';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { VueFormatterServiceInterfaceV1 } from '@oneaudi/vue-formatter-service';
import { UniversalEditorProvider } from '@oneaudi/falcon-tools';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import React from 'react';
import { RenderModeServiceV1 } from '@oneaudi/render-mode-service';
import FeatureApp, { FeatureAppProps } from './FeatureApp';
import ContextProvider from './context/Context';
import { createInitialState, deserializeState } from './utils/stateUtils';
import { mapHeadlessContent } from './utils/mapHeadlessContent';

export interface FeatureServiceDependencies extends FeatureServices {
  readonly 'audi-content-service': ContentServiceV1;
  readonly 'dbad:audi-vue-formatter-service': VueFormatterServiceInterfaceV1;
  readonly 'locale-service': LocaleServiceV1;
  readonly 's2:logger'?: Logger;
  readonly 'audi-footnote-reference-service': AudiFootnoteRefernceServiceScopeManagerInterfaceV3;
  readonly 's2:async-ssr-manager'?: AsyncSsrManagerV1;
  readonly 's2:serialized-state-manager'?: SerializedStateManagerV1;
  readonly 'audi-tracking-service': TrackingServiceV2;
  readonly 'audi-render-mode-service': RenderModeServiceV1;
}

export type AsyncStateHolder = (() => Promise<FeatureAppProps>) | FeatureAppProps | undefined;

const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp, FeatureServiceDependencies> = {
  dependencies: {
    featureServices: {
      'audi-content-service': '^1.0.0',
      'dbad:audi-vue-formatter-service': '^1.0.0',
      'locale-service': '^1.0.0',
      'audi-footnote-reference-service': '^3.0.0',
      'audi-render-mode-service': '^1.0.0',
    },
    externals: {
      react: '^17.0.2 || ^18.0.2',
      'react-dom': '^17.0.2 || ^18.0.2',
      '@audi/audi-ui-react': '^3.x.x',
      '@feature-hub/react': '^3.6.0',
      'styled-components': '^5.3.0',
    },
  },
  optionalDependencies: {
    featureServices: {
      's2:logger': '^1.0.0',
      's2:async-ssr-manager': '^1.0.0',
      'audi-tracking-service': '^2.0.0',
      's2:serialized-state-manager': '^1.0.0',
      'audi-render-mode-service': '^1.0.0',
    },
  },

  create: ({ featureServices }: FeatureAppEnvironment<FeatureServiceDependencies, void>) => {
    const {
      's2:logger': logger,
      'audi-content-service': contentService,
      'dbad:audi-vue-formatter-service': vueFormatterService,
      'locale-service': localeService,
      'audi-footnote-reference-service': footnoteReferenceServiceScopeManager,
      's2:async-ssr-manager': asyncSsrManager,
      's2:serialized-state-manager': serializedStateManager,
      'audi-tracking-service': trackingService,
      'audi-render-mode-service': renderModeService,
    } = featureServices;
    logger?.info('Feature App created.');

    if (trackingService) {
      trackingService.featureAppName = 'fa-modular-editorial';
    }

    const footnoteReferenceService =
      footnoteReferenceServiceScopeManager?.getDefaultScopeRefService();
    const renderMode = renderModeService?.getRenderMode?.() ?? 0;
    const initialContent = contentService.getContent();
    console.log('initialContent: ', initialContent);
    const content = mapHeadlessContent(initialContent);
    console.log('content: ', content);

    let asyncStateHolder: AsyncStateHolder;
    if (asyncSsrManager) {
      asyncSsrManager.scheduleRerender(
        (async () => {
          if (content) {
            asyncStateHolder = await createInitialState(
              content,
              vueFormatterService,
              localeService,
              logger,
            );
            serializedStateManager?.register(() => JSON.stringify(asyncStateHolder));
          }
        })(),
      );
    } else {
      const serializedState = serializedStateManager?.getSerializedState();

      if (serializedState) {
        asyncStateHolder = deserializeState(serializedState);
      } else {
        logger?.warn(
          `Serialized state not found! Possible reasons: \n 1. Running app in dev mode using the "audi-static-demo-integrator" which does not support SSR \n 2. Running app in prod mode and SSR is broken`,
        );
        if (content) {
          asyncStateHolder = () =>
            createInitialState(content, vueFormatterService, localeService, logger);
        }
      }
    }
    return {
      render: () => {
        return renderModeService && renderMode === 1 ? (
          <UniversalEditorProvider
            contentService={contentService}
            renderModeService={renderModeService}
          >
            <InViewContextProvider>
              <ThemeProvider>
                <ContentContextProvider contentService={contentService}>
                  <FootnoteContextProvider footnoteReferenceService={footnoteReferenceService}>
                    <div data-fefa-custom-id={footnoteReferenceService.getConsumerId()}>
                      <ContextProvider
                        localeService={localeService}
                        logger={logger}
                        vueFormatterService={vueFormatterService}
                        trackingService={trackingService}
                      >
                        <FeatureApp asyncStateHolder={asyncStateHolder} />
                      </ContextProvider>
                    </div>
                  </FootnoteContextProvider>
                </ContentContextProvider>
              </ThemeProvider>
            </InViewContextProvider>
          </UniversalEditorProvider>
        ) : (
          <InViewContextProvider>
            <ThemeProvider>
              <ContentContextProvider contentService={contentService}>
                <FootnoteContextProvider footnoteReferenceService={footnoteReferenceService}>
                  <div data-fefa-custom-id={footnoteReferenceService.getConsumerId()}>
                    <ContextProvider
                      localeService={localeService}
                      logger={logger}
                      vueFormatterService={vueFormatterService}
                      trackingService={trackingService}
                    >
                      <FeatureApp asyncStateHolder={asyncStateHolder} />
                    </ContextProvider>
                  </div>
                </FootnoteContextProvider>
              </ContentContextProvider>
            </ThemeProvider>
          </InViewContextProvider>
        );
      },
    };
  },
};

export default featureAppDefinition;
