import { RelatedElements } from '../context/useTrackingManager';
import { Content, Module } from '../EditorContentTypes';

export const countNumberOfImagesInAllModules = (content: Content) => {
  const imagesAdditionalModules = content.additionalModules.map((module) => {
    return module.image;
  });
  const allModules = [content.topModule.image, ...imagesAdditionalModules] || [];

  return allModules
    .filter((item) => item !== undefined)
    .reduce(
      (imgCount, { imageMobile, imageDesktop }) =>
        imgCount + (imageMobile.assetValue && imageDesktop.assetValue ? 1 : 0),
      0,
    );
};

export const createRelatedElementsArray = (content: Content) => {
  const relatedElements: RelatedElements[] = [];

  const createLinkElements = (module: Module, moduleIndex: number) => {
    if (module.links && module.links.length > 0) {
      module.links.forEach((link, i) => {
        const linkIndexInModule = i + 1;
        relatedElements.push({
          elementName: 'link',
          elementValue: `${moduleIndex}-${linkIndexInModule}-${link.text}`,
        });
      });
    }
  };
  const indexTopModule = 1;
  createLinkElements(content.topModule, indexTopModule);
  content.additionalModules.forEach((module, index) => {
    const indexAdditonalModule = index + 2;
    createLinkElements(module, indexAdditonalModule);
  });

  return relatedElements;
};

export const checkLinkTarget = (url: string) => {
  const tempLink = document.createElement('a');
  tempLink.href = url;

  if (tempLink.hostname === window.location.hostname) {
    return 'internal_link';
  }
  return 'exit_link';
};
